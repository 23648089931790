import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Select, { components, InputAction } from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Form
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const MySwal = withReactContent(Swal);

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

    const comparator = (v1, v2) => v1.value - v2.value;


  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (optionProps) => (
    <components.Option {...optionProps}>
      {optionProps.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={optionProps.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={optionProps.value}
          type="checkbox"
          checked={optionProps.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{optionProps.label}</label>
    </components.Option>
  );

  const Input = (inputProps) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={inputProps.selectProps.menuIsOpen} {...inputProps}>
          {inputProps.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={inputProps.selectProps.menuIsOpen} {...inputProps}>
            {inputProps.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue,
    event
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt) => opt.label === label)
                .length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <Select
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Option: Option,
          Input: Input,
          ...props.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
    );
  }

  return (
    <Select
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
    />
  );
};

const AddDealerPostCodes = () => {
  const selectRef = useRef(null);
  const { id } = useParams();
  const [provinces, setProvinces] = useState([]);
  const [dealerDetails, setDealerDetails] = useState([{ province: null, fsaCodes: [], fsaOptions: [] }]);

  useEffect(() => {
    fetch('https://api.icarbuyer.co/api/provinces/all-provinces')
      .then(response => response.json())
      .then(data => {
        const provinceOptions = data.map(province => ({
          value: province.provinceName,
          label: province.provinceName
        }));
        setProvinces(provinceOptions);
      });
  }, []);

  const handleProvinceChange = (selectedProvince, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].province = selectedProvince;
    newDealerDetails[index].fsaCodes = [];
    setDealerDetails(newDealerDetails);

    if (selectedProvince) {
      fetch(`https://api.icarbuyer.co/api/provinces/fsas/${selectedProvince.value}`)
        .then(response => response.json())
        .then(data => {
          const fsaOptions = data.fsas.map(fsa => ({
            value: fsa.fsaCode,
            label: fsa.fsaCode
          }));
          const updatedDealerDetails = [...newDealerDetails];
          updatedDealerDetails[index].fsaOptions = fsaOptions;
          setDealerDetails(updatedDealerDetails);
        });
    }
  };

  const handleFsaChange = (selectedFsaCodes, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].fsaCodes = selectedFsaCodes;
    setDealerDetails(newDealerDetails);
  };

  const addMoreDealerDetails = () => {
    setDealerDetails([...dealerDetails, { province: null, fsaCodes: [], fsaOptions: [] }]);
  };

  const removeDealerDetails = (index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails.splice(index, 1);
    setDealerDetails(newDealerDetails.length ? newDealerDetails : [{ province: null, fsaCodes: [], fsaOptions: [] }]);
  };

  const saveDetails = async () => {
    try {
      const formattedDetails = dealerDetails.map(detail => ({
        province: detail.province ? detail.province.value : null,
        fsaCodes: detail.fsaCodes.map(fsa => fsa.value)
      }));

      const apiUrl = `https://api.icarbuyer.co/api/dealer/dealers/addProvinces/${id}`;

      const response = await axios.post(apiUrl, { provinces: formattedDetails });

      console.log('Saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving details:', error);
    }
  };

  const selectedProvinces = dealerDetails.map(detail => detail.province ? detail.province.value : null);
  const availableProvinces = provinces.filter(province => !selectedProvinces.includes(province.value));

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="text-info m-1">ADD DEALER PROVINCES & POST CODES</h4>
                <div className="page-title-right">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active className="text-info">Add Dealer Post codes</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card className="custom-shadow rounded-lg border my-2">
                <Card.Body>
                  {dealerDetails.map((detail, index) => (
                    <div key={index} className="mb-3">
                      <Row className="align-items-end">
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Province</Form.Label>
                            <Select
                              value={detail.province}
                              onChange={(selectedProvince) => handleProvinceChange(selectedProvince, index)}
                              options={availableProvinces}
                              classNamePrefix="select"
                              placeholder="Select Province"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={5}>
                          <Form.Group>
                            <Form.Label>FSA Codes</Form.Label>
                            <MultiSelect
                              value={detail.fsaCodes}
                              onChange={(selectedFsaCodes) => handleFsaChange(selectedFsaCodes, index)}
                              options={detail.fsaOptions}
                              isMulti={true}
                              classNamePrefix="select"
                              placeholder="Select FSA Codes"
                              isSelectAll={true}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} className="d-flex align-items-end">
                          <div className="d-flex justify-content-between w-100">
                            {dealerDetails.length > 1 && (
                              <Button variant="danger" onClick={() => removeDealerDetails(index)} className="mr-2">
                                Remove
                              </Button>
                            )}
                            {index === dealerDetails.length - 1 && (
                              <Button variant="primary" onClick={addMoreDealerDetails}>
                                Add More
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-3">
                    <Button variant="success" onClick={saveDetails} className="mx-2">
                      Save
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddDealerPostCodes;
