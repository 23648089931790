import React, { Component, Suspense, useState } from 'react'
import { HashRouter, Route, Routes, Navigate, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import './App.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import useAuthStore from './hooks/useAuthStore'
import DefaultLayout from './layout/DefaultLayout'
import Login from './views/login/Login'
import DealerRegistration from './views/dealerRegistration/DealerRegistration'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// const Login = React.lazy(() => import('./views/login/Login.js'))

const App = () => {
  const { token } = useAuthStore()
  // const token = true
  // console.log(token)
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/*" element={token ? <DefaultLayout /> : <Navigate to="/login" />} />
        <Route path="/dealer-registration" element={<DealerRegistration/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App

//   <BrowserRouter>
//   <Routes>
//     <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />
//     <Route path="/*" element={token ? <DefaultLayout /> : <Navigate to="/login" />} />
//   </Routes>
// </BrowserRouter>

/*/
 directly dashboard will open
   <BrowserRouter>
    <Routes>
    <Route path=''  element={<Navigate to={'/login'} />}/>
      <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <DefaultLayout />} />
      <Route path="/*" element={token ? <DefaultLayout /> : <DefaultLayout />} />
    </Routes>
  </BrowserRouter>
  
  */
