import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Pagination,
  Modal,
  Form,
  Image,
} from 'react-bootstrap'

const MySwal = withReactContent(Swal)

const FaqCategories = () => {
  const [categoriesData, setCategoriesData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [categoryIcon, setCategoryIcon] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editCategoryId, setEditCategoryId] = useState(null)

  const apiBaseUrl = 'https://api.icarbuyer.co'

  useEffect(() => {
    fetch(`${apiBaseUrl}/api/faqQuestions/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategoriesData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching categories:', error)
        setLoading(false)
      })
  }, [])

  const handleAddCategory = () => {
    setIsEditing(false)
    setCategoryName('')
    setCategoryIcon(null)
    setShowModal(true)
  }

  const handleEditCategory = (category) => {
    setIsEditing(true)
    setEditCategoryId(category._id)
    setCategoryName(category.name)
    setCategoryIcon(null) // We'll handle icon updates separately
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
    setCategoryName('')
    setCategoryIcon(null)
    setIsEditing(false)
    setEditCategoryId(null)
  }

  const handleModalSubmit = () => {
    if (!categoryName.trim()) {
      MySwal.fire('Error', 'Category name cannot be empty.', 'error')
      return
    }

    const formData = new FormData()
    formData.append('name', categoryName)
    if (categoryIcon) {
      formData.append('icon', categoryIcon)
    }

    if (isEditing) {
      // Edit category
      fetch(`${apiBaseUrl}/api/faqQuestions/categories/${editCategoryId}`, {
        method: 'PUT',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Failed to update category.')
          }
        })
        .then((data) => {
          setCategoriesData((prevData) =>
            prevData.map((cat) => (cat._id === editCategoryId ? data : cat)),
          )
          MySwal.fire('Success', 'Category updated successfully.', 'success')
          handleModalClose()
        })
        .catch((error) => {
          console.error('Error updating category:', error)
          MySwal.fire('Error', 'Failed to update category.', 'error')
        })
    } else {
      // Add new category
      fetch(`${apiBaseUrl}/api/faqQuestions/categories`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Failed to add category.')
          }
        })
        .then((data) => {
          setCategoriesData((prevData) => [...prevData, data])
          MySwal.fire('Success', 'Category added successfully.', 'success')
          handleModalClose()
        })
        .catch((error) => {
          console.error('Error adding category:', error)
          MySwal.fire('Error', 'Failed to add category.', 'error')
        })
    }
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${apiBaseUrl}/api/faqQuestions/categories/${id}`, {
          method: 'DELETE',
        })
          .then((response) => {
            if (response.ok) {
              setCategoriesData((prevData) => prevData.filter((cat) => cat._id !== id))
              MySwal.fire('Deleted!', 'Category has been deleted.', 'success')
            } else {
              throw new Error('Failed to delete category.')
            }
          })
          .catch((error) => {
            console.error('Error deleting category:', error)
            MySwal.fire('Error', 'Failed to delete category.', 'error')
          })
      }
    })
  }

  const filteredCategories = categoriesData.filter((cat) =>
    cat.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCategories.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setCurrentPage(pageNumber)
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">FAQ Categories</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">FAQ Categories</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row className="d-flex justify-content-between">
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0">{filteredCategories.length}</h3>
                        <p className="text-secondary">Total Categories</p>
                      </Col>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder="Search Categories..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <div>
                      <Button className="mb-3" variant="info" onClick={handleAddCategory}>
                        Add New Category
                      </Button>

                      <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {isEditing ? 'Edit Category' : 'Add New Category'}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="formCategoryName">
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter category name"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group controlId="formCategoryIcon" className="mt-3">
                              <Form.Label>Category Icon (Optional)</Form.Label>
                              <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => setCategoryIcon(e.target.files[0])}
                              />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleModalClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleModalSubmit}>
                            {isEditing ? 'Update Category' : 'Save Category'}
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th>Icon</th>
                            <th>Category Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((cat) => (
                            <tr key={cat?._id}>
                              <td>
                                {cat.icon ? (
                                  <Image
                                    src={`${apiBaseUrl}/static/${cat.icon}`}
                                    alt={cat.name}
                                    width={50}
                                    height={50}
                                    rounded
                                    style={{
                                        backgroundColor: '#d3d3d3', // Darker gray background
                                        padding: '5px',             // Optional: Adds some padding around the image
                                        borderRadius: '4px',        // Optional: Slightly rounded corners for better aesthetics
                                      }} // Add background color here
                                  />
                                ) : (
                                  'No Icon'
                                )}
                              </td>

                              <td>{cat?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  className="me-2 btn-sm"
                                  onClick={() => handleEditCategory(cat)}
                                >
                                  <FaRegEdit />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete(cat?._id)}
                                  className="btn-sm"
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination>
                          <Pagination.First onClick={() => paginate(1)} />
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(totalPages).keys()].map((index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last onClick={() => paginate(totalPages)} />
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default FaqCategories
