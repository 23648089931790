import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Breadcrumb,
  Accordion,
  Table,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Constants } from 'src/Constants';

const MySwal = withReactContent(Swal);

const ViewDealer = () => {
  const { id } = useParams();
  const [dealerInfo, setDealerInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDealerInfo = async () => {
      try {
        const response = await fetch(Constants.getDealerById + id);
        if (!response.ok) {
          throw new Error('Failed to fetch dealer info');
        }
        const data = await response.json();
        setDealerInfo(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dealer info:', error);
        setLoading(false);
      }
    };

    fetchDealerInfo();
  }, [id]);

  const handleGoBack = () => {
    window.history.back();
  };

  // Helper function to process car details
  const getProcessedCarDetails = (carDetails) => {
    const processedDetails = {};

    carDetails.forEach((detail) => {
      const { make, vehicleType, carModels } = detail;
      if (!processedDetails[make]) {
        processedDetails[make] = {};
      }
      if (!processedDetails[make][vehicleType]) {
        processedDetails[make][vehicleType] = new Set();
      }
      carModels.forEach((model) => {
        processedDetails[make][vehicleType].add(model);
      });
    });

    return processedDetails;
  };

  const processedCarDetails =
    dealerInfo && dealerInfo.carDetails.length > 0
      ? getProcessedCarDetails(dealerInfo.carDetails)
      : null;

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VIEW DEALER</h4>
                <div className="page-title-right">
                  <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>View Dealer</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>

          {loading ? (
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner animation="border" variant="info" />
              </Col>
            </Row>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <Card className="custom-shadow rounded-lg border">
                  <Card.Body>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Dealer Personal Information</Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <tbody>
                              {/* <tr>
                                <td>First Name:</td>
                                <td>{dealerInfo?.firstName}</td>
                              </tr>
                              <tr>
                                <td>Last Name:</td>
                                <td>{dealerInfo?.lastName}</td>
                              </tr> */}
                              <tr>
                                <td>Name of Dealership:</td>
                                <td>{dealerInfo?.businessName}</td>
                              </tr>
                              <tr>
                                <td>Email:</td>
                                <td>{dealerInfo?.email}</td>
                              </tr>
                              <tr>
                                <td>Business Telephone Number:</td>
                                <td>{dealerInfo?.phone}</td>
                              </tr>
                              <tr>
                                <td>Category:</td>
                                <td>{dealerInfo?.category}</td>
                              </tr>
                              <tr>
                                <td>Division:</td>
                                <td>{dealerInfo?.division}</td>
                              </tr>
                              
                              <tr>
                                <td>Website:</td>
                                <td>{dealerInfo?.website}</td>
                              </tr>
                              <tr>
                                <td>Fax:</td>
                                <td>{dealerInfo?.fax}</td>
                              </tr>
                              <tr>
                                <td>TollFree:</td>
                                <td>{dealerInfo?.tollFree}</td>
                              </tr>
                              <tr>
                                <td>Twitter:</td>
                                <td>{dealerInfo?.twitter}</td>
                              </tr>
                              <tr>
                                <td>Youtube:</td>
                                <td>{dealerInfo?.youtube}</td>
                              </tr>
                              <tr>
                                <td>Facebook:</td>
                                <td>{dealerInfo?.facebook}</td>
                              </tr>
                              <tr>
                                <td>Other Social:</td>
                                <td>{dealerInfo?.otherSocial}</td>
                              </tr>
                              {/* <tr>
                                <td>Company Details:</td>
                                <td>{dealerInfo?.companyDetails}</td>
                              </tr> */}
                              <tr>
                                <td>Street Address:</td>
                                <td>{dealerInfo?.address}</td>
                              </tr>
                              <tr>
                                <td>Suburb:</td>
                                <td>{dealerInfo?.suburb}</td>
                              </tr>
                              <tr>
                                <td>Province:</td>
                                <td>{dealerInfo?.province}</td>
                              </tr>
                              <tr>
                                <td>Post Code:</td>
                                <td>{dealerInfo?.postCodes}</td>
                              </tr>
                              <tr>
                                <td>Status:</td>
                                <td>{dealerInfo?.status?.toUpperCase()}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      {processedCarDetails && (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Dealer Makes, Vehicle Types & Cars</Accordion.Header>
                          <Accordion.Body>
                            {Object.entries(processedCarDetails).map(([make, vehicleTypes], index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: '20px',
                                  borderBottom: '1px solid #ddd',
                                  paddingBottom: '10px',
                                }}
                              >
                                <h5 style={{ color: '#007bff', marginBottom: '10px' }}>{make}</h5>
                                {Object.entries(vehicleTypes).map(([vehicleType, models], index) => (
                                  <div
                                    key={index}
                                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                                  >
                                    <strong style={{ fontSize: '16px' }}>{vehicleType}:</strong>
                                    <span style={{ fontSize: '16px', color: '#555' }}>
                                      {' '}
                                      {Array.from(models).join(', ')}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {dealerInfo?.provinces && dealerInfo.provinces.length > 0 && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Dealer Provinces and FSA Codes</Accordion.Header>
                          <Accordion.Body>
                            {dealerInfo.provinces.map((province, index) => (
                              <Card key={index} className="mb-3">
                                <Card.Body>
                                  <Card.Title>{province?.province}</Card.Title>
                                  <Row>
                                    {province?.fsaCodes.map((code, codeIndex) => (
                                      <Col key={codeIndex} xs={6} md={4} lg={3}>
                                        <Card.Text>{code}</Card.Text>
                                      </Col>
                                    ))}
                                  </Row>
                                </Card.Body>
                              </Card>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {!processedCarDetails && (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Dealer Makes, Vehicle Types & Cars</Accordion.Header>
                          <Accordion.Body>
                            <p>No Makes, Vehicle Types & Cars data available</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {(!dealerInfo?.provinces || dealerInfo.provinces.length === 0) && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Dealer Provinces and FSA Codes</Accordion.Header>
                          <Accordion.Body>
                            <p>No Provinces and FSA Codes data available</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                    </Accordion>
                    <Button variant="secondary" onClick={handleGoBack} className="mt-3">
                      Go Back
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ViewDealer;
