import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { Container, Row, Col, Card, Button, Breadcrumb, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState('');
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef('Select all');
  const allOption = { value: '*', label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()));

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      {props.value === '*' && !isAllSelected.current && filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: '5px' }}>{props.label}</label>
    </components.Option>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== '*' && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === '*' && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '') setSelectInput('');
  };

  const handleChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      const newSelected = isAllSelected.current ? [] : [...props.options];
      isAllSelected.current = !isAllSelected.current;
      props.onChange(newSelected);
    } else {
      props.onChange(selected);
    }
  };

  const customStyles = {
    multiValueLabel: (base) => ({
      ...base,
      backgroundColor: 'lightgray',
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: 'lightgray',
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: '65px',
      overflow: 'auto',
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected ? '#DEEBFF' : isFocused ? '#F5F5F5' : null,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Select
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      options={[allOption, ...props.options]}
      onChange={handleChange}
      components={{ Option }}
      filterOption={customFilterOption}
      menuPlacement={props.menuPlacement ?? 'auto'}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );
};

const AddDealerCar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dealerDetails, setDealerDetails] = useState([{ make: '', vehicleType: '', carModels: [] }]);
  const [makesData, setMakesData] = useState({});
  const [carData, setCarData] = useState({});

  useEffect(() => {
    fetch(`https://api.icarbuyer.co/api/dealer/dealerMakes/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const fetchedData = {};
        data.data.forEach((item) => {
          fetchedData[item.make] = item.vehicleTypes;
        });
        setMakesData(fetchedData);
      })
      .catch((error) => {
        MySwal.fire('Error', 'Failed to fetch makes and vehicle types', 'error');
        console.error('Error fetching makes and vehicle types:', error);
      });
  }, [id]);

  const handleMakeChange = (selectedMake, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].make = selectedMake.value;
    newDealerDetails[index].vehicleType = '';
    newDealerDetails[index].carModels = [];
    setDealerDetails(newDealerDetails);
  };

  const handleVehicleTypeChange = (selectedVehicleType, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].vehicleType = selectedVehicleType.value;
    newDealerDetails[index].carModels = [];
    setDealerDetails(newDealerDetails);

    const { make } = newDealerDetails[index];
    if (make && selectedVehicleType.value) {
      fetch(
        `https://api.icarbuyer.co/api/car/getModelNamesByMakeAndType/${make}/${selectedVehicleType.value}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCarData((prevState) => ({
            ...prevState,
            [`${make}-${selectedVehicleType.value}`]: data,
          }));
        })
        .catch((error) => {
          MySwal.fire('Error', 'Failed to fetch car models', 'error');
          console.error('Error fetching car models:', error);
        });
    }
  };

  const handleCarModelChange = (selectedCarModels, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].carModels = selectedCarModels.map((carModel) => carModel.value);
    setDealerDetails(newDealerDetails);
  };

  const addMoreDealerDetails = () => {
    setDealerDetails([...dealerDetails, { make: '', vehicleType: '', carModels: [] }]);
  };

  const removeDealerDetails = (index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails.splice(index, 1);
    setDealerDetails(
      newDealerDetails.length ? newDealerDetails : [{ make: '', vehicleType: '', carModels: [] }]
    );
  };

  const getAvailableMakes = () => {
    const selectedMakesWithAllTypesSelected = dealerDetails
      .filter(detail => detail.make)
      .reduce((acc, detail) => {
        const make = detail.make;
        const typesForMake = dealerDetails
          .filter(d => d.make === make)
          .map(d => d.vehicleType);
        if (typesForMake.length === makesData[make].length) {
          acc.push(make);
        }
        return acc;
      }, []);

    return Object.keys(makesData)
      .filter(make => !selectedMakesWithAllTypesSelected.includes(make))
      .map(make => ({
        value: make,
        label: make,
      }));
  };

  const getAvailableVehicleTypes = (make) => {
    const selectedVehicleTypes = dealerDetails
      .filter(detail => detail.make === make)
      .map(detail => detail.vehicleType)
      .filter(Boolean);

    return makesData[make].filter(type => !selectedVehicleTypes.includes(type)).map(type => ({
      value: type,
      label: type,
    }));
  };

  const saveDetails = () => {
    const payload = {
      carDetails: dealerDetails,
    };

    fetch(`https://api.icarbuyer.co/api/dealer/addCarDetails/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Car details added successfully') {
          MySwal.fire('Success', data.message, 'success').then(() => {
            navigate('/customerList');
          });
        } else {
          MySwal.fire('Error', data.message || 'Failed to save details', 'error');
        }
      })
      .catch((error) => {
        MySwal.fire('Error', 'Failed to save details', 'error');
        console.error('Error saving details:', error);
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <Col className="col-md-6">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Add Dealer Car</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <Card.Header className="border-bottom">
              <h6 className="m-0">Add Dealer Car</h6>
            </Card.Header>
            <Card.Body className="p-0 pb-3">
              <Form>
                {dealerDetails.map((dealerDetail, index) => (
                  <Row form className="p-3" key={index}>
                    <Col md="3" className="form-group">
                      <label htmlFor={`make-${index}`}>Make</label>
                      <Select
                        id={`make-${index}`}
                        value={dealerDetail.make ? { value: dealerDetail.make, label: dealerDetail.make } : null}
                        onChange={(selectedMake) => handleMakeChange(selectedMake, index)}
                        options={getAvailableMakes()}
                        placeholder="Select Make"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor={`vehicleType-${index}`}>Vehicle Type</label>
                      <Select
                        id={`vehicleType-${index}`}
                        value={
                          dealerDetail.vehicleType
                            ? { value: dealerDetail.vehicleType, label: dealerDetail.vehicleType }
                            : null
                        }
                        onChange={(selectedVehicleType) =>
                          handleVehicleTypeChange(selectedVehicleType, index)
                        }
                        options={dealerDetail.make ? getAvailableVehicleTypes(dealerDetail.make) : []}
                        placeholder="Select Vehicle Type"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor={`carModels-${index}`}>Car Models</label>
                      <MultiSelect
                        id={`carModels-${index}`}
                        value={
                          dealerDetail.carModels.map((model) => ({
                            value: model,
                            label: model,
                          })) || []
                        }
                        onChange={(selectedCarModels) => handleCarModelChange(selectedCarModels, index)}
                        options={
                          dealerDetail.make && dealerDetail.vehicleType && carData[`${dealerDetail.make}-${dealerDetail.vehicleType}`]
                            ? carData[`${dealerDetail.make}-${dealerDetail.vehicleType}`].map((model) => ({
                                value: model,
                                label: model,
                              }))
                            : []
                        }
                        placeholder="Select Car Models"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label>&nbsp;</label>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="danger" onClick={() => removeDealerDetails(index)}>
                          Remove
                        </Button>
                        {index === dealerDetails.length - 1 && (
                          <Button
                            variant="primary"
                            className="ml-3"
                            onClick={addMoreDealerDetails}
                          >
                            Add More
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                ))}
                <Button variant="primary" onClick={saveDetails}  className="mx-3">
                  Save Details
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default AddDealerCar;
